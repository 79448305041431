import {
  Employee,
  GanttTabBarTab,
  TimeRange,
  UnplannedTasksType,
  WorkTask,
} from "../types/ganttChart";
import ganttCHartValues, {
  ganttChartVerticalValues,
} from "../utils/ganttChartValues";

class ganttChartService {
  static isEven(number: number) {
    return number % 2 === 0;
  }

  // Todo move into utils
  static rowColor = (
    type: GanttTabBarTab,
    index: number,
    selectedGanttChartTab: GanttTabBarTab
  ) => {
    switch (selectedGanttChartTab) {
      case "Both":
        if (type === "Original") {
          return "var(--col-primary-40)";
        } else return "white";
      default:
        if (index % 2 === 0) return "white";
        else return "var(--col-primary-40)";
    }
  };

  static getPixelsPerMinute = (
    svgWidth: number,
    numberOfHoursShownOnTimeline: number
  ) => {
    const totalMinutes = (numberOfHoursShownOnTimeline + 0.5) * 60;
    return svgWidth / totalMinutes;
  };

  static getVerticalPixelsPerMinute = (
    numberOfHoursShownOnTimeline: number
  ) => {
    const verticalViewPort = document.getElementById(
      "vertical-ganttChart-svg-wrapper"
    )!.clientHeight;
    const totalMinutes = numberOfHoursShownOnTimeline * 60;
    return verticalViewPort / totalMinutes;
  };

  static timeDifferenceInMinutes(startDate: string, endDate: string) {
    const timespan =
      new Date(endDate).getTime() - new Date(startDate).getTime();
    return timespan / 60000;
  }

  static findTaskPlacementOnYAxisForPlannedTask = (
    index: number,
    rowHeight: number,
    selectedGanttChartTab: GanttTabBarTab,
    offSet: number
  ) => {
    let placementOnYAxis;

    if (selectedGanttChartTab === "Both") {
      placementOnYAxis = index * rowHeight * 2;
    } else {
      placementOnYAxis = index * rowHeight;
    }
    return placementOnYAxis + ganttCHartValues.headerRowHeight + offSet;
  };

  static getPlacementOnYaxisForWhiteRow = (
    index: number,
    selectedGanttChartTab: GanttTabBarTab,
    individualRowHeight: number
  ) => {
    if (selectedGanttChartTab === "Both") {
      return index * individualRowHeight * 2 + ganttCHartValues.headerRowHeight;
    } else {
      return index * individualRowHeight + ganttCHartValues.headerRowHeight;
    }
  };

  static getPlacementOnYAxisForColoredRow = (index: number) => {
    return (
      (index + 0.5) * ganttCHartValues.rowHeightForBoth +
      ganttCHartValues.headerRowHeight
    );
  };

  static earliestStartDate: number | undefined;
  static latestEndDate: number | undefined;

  static checkDateAgainstEarliestDate = (taskStartDate: number) => {
    if (!this.earliestStartDate) this.earliestStartDate = taskStartDate;
    if (taskStartDate < this.earliestStartDate) {
      this.earliestStartDate = taskStartDate; // Update earliest start date if found earlier task
    }
  };

  static checkDateAgainstLatestDate = (taskEndDate: number) => {
    if (!this.latestEndDate) this.latestEndDate = taskEndDate;
    if (this.latestEndDate < taskEndDate) {
      this.latestEndDate = taskEndDate; // Update earliest start date if found earlier task
    }
  };

  static getStartTime = (task: WorkTask) => {
    let startTime;
    if (task.start) startTime = task.start;
    else if (task.earliestStart) startTime = task.earliestStart;
    else if (task.preferredEarliestStart)
      startTime = task.preferredEarliestStart;
    return startTime;
  };

  static sortAccordingToStartDate = (
    employees: Employee[],
    unplannedNexusTasks: UnplannedTasksType,
    selectedGanttChartTab: GanttTabBarTab,
    unplannedPondooTasks?: UnplannedTasksType
  ): TimeRange => {
    this.earliestStartDate = undefined;
    this.latestEndDate = undefined;

    employees.forEach((employee) => {
      // todo: is this needed still?
      // if (
      //   employee.pondooTasks &&
      //   (selectedGanttChartTab === "Pondoo" || selectedGanttChartTab === "Both")
      // ) {
      //   employee.pondooTasks.workTasks.forEach((task) => {
      //     this.checkDateAgainstEarliestDate(new Date(task.start).getTime());
      //     this.checkDateAgainstLatestDate(new Date(task.end).getTime());
      //   });
      // }
      // Todo: do we need an if statement here?
      // if (
      //   selectedGanttChartTab === "Nexus" ||
      //   selectedGanttChartTab === "Both"
      // ) {
      employee.tasks.workTasks.forEach((task) => {
        this.checkDateAgainstEarliestDate(new Date(task.start).getTime());
        this.checkDateAgainstLatestDate(new Date(task.end).getTime());
      });
      // }
    });
    // if (selectedGanttChartTab === "Nexus" || selectedGanttChartTab === "Both") {
    //   unplannedNexusTasks.workTasks.forEach((task) => {
    //     const startTime = this.getStartTime(task);

    //     if (startTime)
    //       this.checkDateAgainstEarliestDate(new Date(startTime).getTime());
    //     this.checkDateAgainstLatestDate(
    //       new Date(task.preferredLatestEnd).getTime()
    //     );
    //   });
    // }
    // if (
    //   unplannedPondooTasks &&
    //   (selectedGanttChartTab === "Pondoo" || selectedGanttChartTab === "Both")
    // ) {
    //   unplannedPondooTasks.workTasks.forEach((task) => {
    //     const startTime = this.getStartTime(task);
    //     if (startTime)
    //       this.checkDateAgainstEarliestDate(new Date(startTime).getTime());
    //     this.checkDateAgainstLatestDate(new Date(task.latestEnd).getTime());
    //   });
    // }

    return {
      startDate: this.earliestStartDate ? this.earliestStartDate : 0,
      endDate: this.latestEndDate ? this.latestEndDate : 0,
    };
  };

  // ######################## VERTICAL ########################################
  static getVerticalColumnWidth = (
    svgWrapperWidth: number,
    dataLength: number,
    unplannedColumnWidth: number
  ): number => {
    return (
      (svgWrapperWidth -
        ganttChartVerticalValues.verticalViewHeaderColumn -
        unplannedColumnWidth) /
      dataLength
    );
  };

  static getVerticalSvgWidth = (
    dataLength: number,
    columnWidth: number,
    unplannedColumnWidth: number
  ) => {
    return (
      dataLength * columnWidth +
      ganttChartVerticalValues.verticalViewHeaderColumn +
      unplannedColumnWidth
    );
  };
}

export default ganttChartService;
