import { ScaleColor } from "../components/kpiCards/CitizenServiceCard";

class colors {
  static getBackgroundColor = (value: number) => {
    if (!value || value < 5) return "var(--col-dirt-rose-dark)";
    else if (value >= 5 && value < 8) return "var(--col-taxi-yellow)";
    else return "var(--col-after-eight)";
  };

  static getContinuityAndPreferenceScaleColor = (percent: number) => {
    if (percent < 50)
      return {
        borderColor: "var(--col-dirt-rose)",
        backgroundColor: "var(--col-dirt-rose-very-light)",
      };
    else if (percent >= 50 && percent < 75)
      return {
        backgroundColor: "var(--col-taxi-yellow-light)",
        borderColor: "var(--col-taxi-yellow)",
      };
    else if (percent >= 75)
      return {
        backgroundColor: "var(--col-after-eight-light)",
        borderColor: "var(--col-after-eight)",
      };
  };

  static getRulesScaleColor = (percent: number) => {
    if (percent < 80)
      return {
        borderColor: "var(--col-dirt-rose)",
        backgroundColor: "var(--col-dirt-rose-light)",
      };
    else if (percent >= 80 && percent < 99)
      return {
        backgroundColor: "var(--col-taxi-yellow-light)",
        borderColor: "var(--col-taxi-yellow)",
      };
    else if (percent >= 99)
      return {
        backgroundColor: "var(--col-after-eight-light)",
        borderColor: "var(--col-after-eight)",
      };
  };
}

export default colors;
