import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActiveNexusFilter } from "../../types/ganttChart";
import {
  getActiveNexusFilters,
  getNexusFilters,
  getPlanningDates,
} from "./action";

export type PlanningDate = {
  id: number;
  planningDate: string;
  idScenario?: number;
  nexusIdSolution?: number;
  pondooIdSolution?: number;
};

export interface NexusFilters {
  nexusFilters?: string[];
  fromDate?: string;
  toDate?: string;
  filtersString?: string;
  activeNexusFilters?: ActiveNexusFilter[];
  selectedActiveNexusFilter?: ActiveNexusFilter;
  selectedNexusFilterDate?: string;
  planningDates?: PlanningDate[];
  errorOnFilters?: string;
  errorOnActiveFilters?: string;
  errorOnPlanningDates?: string;
  queuedNexusLandingPlanningDateToReset?: string;
  calendarStartDate?: string;
  calendarEndDate?: string;
}

const initialState: NexusFilters = {};

const nexusFiltersSlice = createSlice({
  name: "gantt-chart",
  initialState,
  reducers: {
    setActiveNexusFilter(state, action) {
      state.selectedActiveNexusFilter = action.payload;
    },
    setSelectedNexusFilterDate(state, action) {
      state.selectedNexusFilterDate = action.payload;
    },
    /**
     * When a planning date is affected by the flow initiated by a Nexus Landing, we enter a race condition where the spinner
     * behavior needs the modification of the planningDates array, but redirecting from /nexus-landing to / will overwrite
     * any such modification. Consequently, we queue up the date(s) here and react to them in the initialization logic in Header.tsx
     * @param state
     * @param action
     */
    queueAffectedNexusLandingPlanningDate(
      state,
      action: PayloadAction<string>,
    ) {
      state.queuedNexusLandingPlanningDateToReset = action.payload;
    },
    dequeueAffectedNexusLandingPlanningDate(state) {
      delete state.queuedNexusLandingPlanningDateToReset;
    },
    /**
     * Unsets the various ids used to identify nexus/pondoo plans. This is used to control the spinner behavior.
     * @param state
     * @param action
     */
    resetSolutionsOnPlanningDate(state, action: PayloadAction<string>) {
      if (!state.planningDates) return;
      const planningDate = state.planningDates?.find(
        (date) => date.planningDate === action.payload,
      );
      if (!planningDate) return;

      state.planningDates = [
        ...state.planningDates.filter((date) => date.id !== planningDate.id),
        {
          ...planningDate,
          idScenario: undefined,
          pondooIdSolution: undefined,
          nexusIdSolution: undefined,
        },
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNexusFilters.fulfilled, (state, action) => {
      state.nexusFilters = action.payload.filters;
    });
    builder.addCase(getNexusFilters.pending, (state) => {
      delete state.nexusFilters;
    });
    // Todo: handle error
    builder.addCase(getNexusFilters.rejected, (state) => {
      state.errorOnFilters = "Failed to retrieve filters.";
    });
    builder.addCase(getActiveNexusFilters.fulfilled, (state, action) => {
      state.activeNexusFilters = action.payload.filters;
    });
    builder.addCase(getActiveNexusFilters.pending, (state) => {
      delete state.activeNexusFilters;
    });
    // Todo: handle error
    builder.addCase(getActiveNexusFilters.rejected, (state) => {
      state.errorOnActiveFilters = "Failed to retrieve filters.";
    });
    builder.addCase(getPlanningDates.fulfilled, (state, action) => {
      state.planningDates = state.queuedNexusLandingPlanningDateToReset
        ? (action.payload.dates as PlanningDate[]).filter(
            (date) =>
              date.planningDate !== state.queuedNexusLandingPlanningDateToReset,
          )
        : action.payload.dates;
      // Consume the queued date if necessary
      // TODO: Attempt to streamline flow by deleting this when we get a websocket message with Nexus data ready
      // if (state.queuedNexusLandingPlanningDateToReset)
      //   delete state.queuedNexusLandingPlanningDateToReset;
      state.calendarStartDate = action.payload.startDate;
      state.calendarEndDate = action.payload.endDate;
    });
    builder.addCase(getPlanningDates.pending, (state) => {
      delete state.planningDates;
    });
    // Todo: handle error
    builder.addCase(getPlanningDates.rejected, (state) => {
      state.errorOnPlanningDates =
        "Failed to retrieve available planning dates.";
    });
  },
});

export const {
  setActiveNexusFilter,
  setSelectedNexusFilterDate,
  resetSolutionsOnPlanningDate,
  queueAffectedNexusLandingPlanningDate,
  dequeueAffectedNexusLandingPlanningDate,
} = nexusFiltersSlice.actions;

export default nexusFiltersSlice.reducer;
