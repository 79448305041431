import labels from "../../utils/labels";
import KPITextLine from "../UI/kpi/KPITextLine";
import KpiCard from "./KpiCard";
import { ReactComponent as BriefcaseIcon } from "../../assets/icons/briefcase.svg";
import { useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import kpiStyles from "../../styles/UI/kpiPage.module.css";
import { KpiWorkTime } from "../../types/kpi";
import ComparisonChip from "../UI/kpi/ComparisonChip";
import comparisonUtil from "../../utils/comparisonUtil";

const WorkTimeCard = ({
  kpiList,
  compareKpiList,
  compare,
}: {
  kpiList: KpiWorkTime;
  compareKpiList: KpiWorkTime;
  compare: boolean;
}) => {
  const { kpiData } = useAppSelector((state) => state.kpiCollectionSlice);
  const [hoursPlanAndOvertime, setHoursPlanAndOvertime] = useState<number>();
  const [hoursUnplannedAndPlanned, setHoursUnplannedAndPlanned] =
    useState<number>();

  useEffect(() => {
    if (kpiData) {
      setHoursPlanAndOvertime(kpiList.shiftHours + kpiList.overtimeHours);
      setHoursUnplannedAndPlanned(
        kpiList.unplannedHours + kpiList.shiftScheduleHours
      );
    }
  }, [kpiData]);
  const getPercentage = (dividend: number, divisor: number) => {
    return Math.round((dividend / divisor) * 100);
  };
  return (
    <KpiCard
      title="Arbejdstid"
      icon={<BriefcaseIcon />}
      classes="w-[30%] max-w-[430px] mb-[25px] h-[705px]"
    >
      <div className="flex flex-col gap-[50px]">
        {kpiData && hoursPlanAndOvertime && hoursUnplannedAndPlanned && (
          <div className="flex gap-spacing-xl justify-center mt-[25px]">
            <div className={kpiStyles.graphBars}>
              {kpiData!.pondooDashboard.workTime.overtimeHours > 0 && (
                <div
                  style={{
                    height: `${getPercentage(kpiData!.pondooDashboard.workTime.overtimeHours, hoursPlanAndOvertime)}%`,
                    backgroundColor: "var(--col-dirt-rose)",
                    border: "2px solid var(--col-dirt-rose-dark)",
                  }}
                ></div>
              )}
              <div
                className={kpiStyles.individualGraphBars}
                style={{
                  height: `${getPercentage(kpiData!.pondooDashboard.workTime.shiftHours, hoursPlanAndOvertime)}%`,
                  backgroundColor: "var(--col-mocha-late)",
                  border: "2px solid var(--col-mocha-cappucino)",
                }}
              ></div>
            </div>
            <div className={kpiStyles.graphBars}>
              {kpiData!.pondooDashboard.workTime.unplannedHours > 0 && (
                <div
                  className={kpiStyles.individualGraphBars}
                  style={{
                    height: `${getPercentage(kpiData!.pondooDashboard.workTime.unplannedHours, hoursPlanAndOvertime)}%`,
                    backgroundColor: "var(--col-taxi-yellow-light)",
                    border: "2px solid var(--col-taxi-yellow)",
                  }}
                ></div>
              )}
              <div
                className={kpiStyles.individualGraphBars}
                style={{
                  height: `${getPercentage(kpiData!.pondooDashboard.workTime.shiftScheduleHours, hoursPlanAndOvertime)}%`,
                  backgroundColor: "var(--col-lavendar-light)",
                  border: "2px solid var(--col-lavendar)",
                }}
              ></div>
            </div>
          </div>
        )}
        <div className="flex flex-col gap-spacing-xl">
          <KPITextLine
            compare={compare}
            title={labels.plan}
            value={`${kpiList.shiftHours.toString()} ${labels.hours}`}
            color="var(--col-mocha-late)"
            borderColor="var(--col-mocha-cappucino)"
          />
          <KPITextLine
            compare={compare}
            title={labels.overtime}
            value={`${kpiList.overtimeHours.toString()} ${labels.hours}`}
            color="var(--col-dirt-rose)"
            borderColor="var(--col-dirt-rose-dark)"
          >
            {compare && (
              <ComparisonChip
                positiveIndicator="NEGATIVE"
                value={comparisonUtil.getRoundedComparison(
                  kpiList.overtimeHours,
                  compareKpiList.overtimeHours
                )}
                id={compareKpiList.overtimeHours}
              />
            )}
          </KPITextLine>
          <KPITextLine
            compare={compare}
            title={labels.unplannedTime}
            value={`${kpiList.unplannedHours.toString()} ${labels.hours}`}
            color="var(--col-taxi-yellow-light)"
            borderColor="var(--col-taxi-yellow)"
          />
          <KPITextLine
            compare={compare}
            title={labels.plannedTime}
            value={`${kpiList.shiftScheduleHours.toString()} ${labels.hours}`}
            color="var(--col-lavendar-light)"
            borderColor="var(--col-lavendar)"
          />
        </div>
      </div>
    </KpiCard>
  );
};

export default WorkTimeCard;
