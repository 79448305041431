import StandardButton from "../UI/buttons/StandardButton";
import { ReactComponent as UploadIcon } from "../../assets/icons/black-upload.svg";
import api, { API_PATHS } from "../../service/api";

const SendToNexus = ({
  disable,
  idScenario,
}: {
  disable: boolean;
  idScenario: number;
}) => {
  const sendToNexus = async () => {
    try {
      const body = {
        idScenario: idScenario,
      };

      const response = await api.post<any>(API_PATHS.postNexusPlan, body);
    } catch {
      //@tanja todo: dispatch to error to toaster functionality.
      //Change error message
      console.log("Noget gik galt, så din plan blev ikke sendt til Nexus");
    }
  };
  return (
    <StandardButton
      onClick={() => sendToNexus()}
      variant="SECONDARY"
      disable={disable}
      icon={<UploadIcon />}
    >
      Send plan til Nexus
    </StandardButton>
  );
};

export default SendToNexus;
